import React from "react";
import NewsArticleLayout from "../../../components/updated-article/NewsArticleLayout.component";

const isNew = true;
const href = "/ratesheet/20240710-Rate-Sheet.pdf";
const date = "10.07.2024";

const Article = () => (
  <NewsArticleLayout href={href} date={date} isNew={isNew}>
    <h1 className="page-title">Changes to our mortgage products</h1>

    <p>With effect from Wednesday 10 July 2024 we will be making the following changes to our residential range:</p>
    <p>
      <strong>Summary of changes</strong>
    </p>
    <ul>
      <li>We are increasing the lending limit on our 70% LTV range from &pound;2,000,000 to &gt;&pound;3,000,000</li>
      <li>We are increasing the lending limit on our 75% LTV range from &pound;2,000,000 to &pound;3,000,000</li>
      <li>We are increasing the lending limit on our 80% LTV range from &pound;1,000,000 to &pound;2,000,000</li>
      <li>We are increasing the lending limit on our 85% LTV range from &pound;750,000 to &pound;2,000,000</li>
      <li>We are increasing the lending limit on our 90% LTV range from &pound;550,000 to &pound;750,000</li>
      <li>We are increasing the lending limit on our 95% LTV range from &pound;500,000 to &pound;570,000</li>
    </ul>
    <p>There are no changes to any of our interest rates.</p>
    <p>An updated mortgage rate sheet will be published on Wednesday 10 July 2024 to reflect these changes.</p>
  </NewsArticleLayout>
);

export default Article;
